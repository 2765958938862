const locale = {
  label: 'label',
  label_confirmation: 'Confirmation',
  label_confirmation_description:
    'Are you sure want to create extra multiplier',
  label_check_again: 'Check Again',
  label_yes_create: 'Yes, Create',
  label_modal_success_title: 'New Extra Multiplier Created',
  label_modal_success_description:
    'The extra multiplier has been created and add to the list',
  label_okay: 'Okay',
  label_create_new_extra_multiplier: 'Create New Extra Multiplier',
  label_channel: 'Channel',
  label_select_level: 'Select Level',
  label_product_type: 'Product Type',
  label_select_product_type: 'Select Product Type',
  label_extra_multiplier_per_level: 'Extra Multiplier Per Level',
  label_placeholder_extra_multiplier_per_level: 'e.g. 1.5',
  label_cancel: 'Cancel',
  label_save: 'Save',
  label_validation_required: 'Must be filled',
  label_validation_min_value_1: 'Minimum extra multiplier per level is 1',
  label_extra_multiplier: 'Extra Multiplier',
  label_create_new: 'Create New',
  label_modal_confirmation_delete_title:
    'Are you sure delete extra multiplier?',
  label_modal_confirmation_delete_description: 'Data can’t be restore',
  label_edit: 'Edit',
  label_delete: 'Delete',
  label_empty_state_title: 'Extra Multiplier Empty',
  label_empty_state_description: `Let's create a new extra multiplier for your customer`,
  label_multiplier: 'Multiplier',
  label_action: 'Action',
  labe_no: 'No',
  label_edit_extra_multiplier: 'Edit Extra Multiplier',
  label_confirmation_description_edit:
    'Are you sure want to edit extra multiplier',
  label_yes_edit: 'Yes, Edit',
  label_modal_success_title_edit: 'Extra Multiplier Updated',
  label_modal_success_description_edit: 'The extra multiplier has been updated',
  label_note_invalid_level_active:
    'level id not match with current active level id',
  label_delete_success: 'Delete Success',
  label_extra_multiplier_deleted: 'Extra Multiplier Deleted',
}

export default locale
