




































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import LoyaltyDropdown from '../../components/LoyaltyDropdown/index.vue'
import controller, {
  UpdateExtraMultiplierPayload,
} from '@/app/ui/controllers/MembershipFormulaController'
import locale from '../locale'
import {
  Dropdown,
  EnumLoyaltiProgramChannel,
  EnumExtraMultiplierProductType,
} from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import { DataExtraMultiplier } from '@/domain/entities/MembershipFormula'
import LoyaltyInput from '../../components/LoyaltyInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { validationMixin } from 'vuelidate'
import {
  minLength,
  minValue,
  required,
  ValidationRule,
} from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators'
import Modal from '@/app/ui/components/Modal/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import { EventBusConstants } from '@/app/infrastructures/misc'
import Note from '@/app/ui/components/Note/Note.vue'

interface ExtraMultiplierInfoInterface {
  id: string
  extra_multiplier: string
}

interface IForm {
  channel: Dropdown
  product: Dropdown
  multiplier: Array<{
    id: number | string
    extraMultiplier: number | string
  }>
}

interface fieldMultiplierInterface {
  [key: string]: string
}

interface ValidationsInterface {
  form: {
    channel: {
      required: () => ValidationRule
    }
    product: {
      required: () => ValidationRule
    }
    multiplier: {
      required: () => ValidationRule
      minLength: ValidationRule
      $each: {
        id: {
          required: () => ValidationRule
        }
        extraMultiplier: {
          required: () => ValidationRule
          minValue: ValidationRule
        }
      }
    }
  }
}

@Component({
  name: 'EditExtraMultiplier',
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    LoyaltyDropdown,
    LoyaltyInput,
    Button,
    Modal,
    ConfirmationIcon,
    Note,
  },
})
export default class EditExtraMultiplier extends Vue {
  controller = controller
  locale = locale
  channel: Dropdown[] = [
    {
      label: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
      value: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
    },
    {
      label: EnumLoyaltiProgramChannel.MARKETPLACE,
      value: EnumLoyaltiProgramChannel.MARKETPLACE,
    },
    {
      label: EnumLoyaltiProgramChannel.RETAIL,
      value: EnumLoyaltiProgramChannel.RETAIL,
    },
    {
      label: EnumLoyaltiProgramChannel.NEW_COD,
      value: EnumLoyaltiProgramChannel.NEW_COD
    }
  ]
  productTypeOptions: Dropdown[] = [
    {
      label: EnumExtraMultiplierProductType.SAMEDAY,
      value: EnumExtraMultiplierProductType.SAMEDAY,
    },
    {
      label: EnumExtraMultiplierProductType.BOSSPACK,
      value: EnumExtraMultiplierProductType.BOSSPACK,
    },
    {
      label: EnumExtraMultiplierProductType.REGPACK,
      value: EnumExtraMultiplierProductType.REGPACK,
    },
    {
      label: EnumExtraMultiplierProductType.JAGOPACK,
      value: EnumExtraMultiplierProductType.JAGOPACK,
    },
    {
      label: EnumExtraMultiplierProductType.BIGPACK,
      value: EnumExtraMultiplierProductType.BIGPACK,
    },
    {
      label: EnumExtraMultiplierProductType.JUMBOPACK,
      value: EnumExtraMultiplierProductType.JUMBOPACK,
    },
    {
      label: EnumExtraMultiplierProductType.INTERPACK,
      value: EnumExtraMultiplierProductType.INTERPACK,
    },
  ]
  form: IForm = {
    channel: this.channel[-1],
    product: this.productTypeOptions[-1],
    multiplier: [],
  }
  showModalConfirmation = false
  fieldMultiplier: fieldMultiplierInterface = {}
  showModalSuccess = false

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        channel: { required },
        product: {
          required,
        },
        multiplier: {
          required,
          minLength: minLength(5),
          $each: {
            id: {
              required,
            },
            extraMultiplier: {
              required,
              minValue: minValue(1),
            },
          },
        },
      },
    }
  }

  created(): void {
    this.fetchMultiplierLevel()
    this.fetchDetailExtraMultiplier()
  }

  fetchMultiplierLevel(): void {
    controller.getMultiplierLevel()
  }

  fetchDetailExtraMultiplier(): void {
    const id = this.$route.params.id
    controller.getDetailExtraMultiplier(id)
  }

  @Watch('controller.detailExtraMultiplier')
  onDetailExtraMultiplierChanged(data: DataExtraMultiplier): void {
    const obj = data.extraMultiplierInfo
      ? JSON.parse(data.extraMultiplierInfo)
      : []
    const res: fieldMultiplierInterface = {}
    obj.forEach((item: ExtraMultiplierInfoInterface) => {
      res[item.id] = item.extra_multiplier
    })

    this.fieldMultiplier = res
    this.form = {
      channel: {
        label: <EnumLoyaltiProgramChannel>data.channel,
        value: <EnumLoyaltiProgramChannel>data.channel,
      },
      product: {
        label: <EnumExtraMultiplierProductType>data.productType,
        value: <EnumExtraMultiplierProductType>data.productType,
      },
      multiplier: Object.entries(this.fieldMultiplier).map(item => ({
        id: Number(item[0]),
        extraMultiplier: item[1],
      })),
    }
  }

  private compareArrays(
    array1: Array<string | number | undefined>,
    array2: Array<string | number | undefined>
  ): boolean {
    return JSON.stringify(array1) === JSON.stringify(array2)
  }

  get isEditable(): boolean {
    const levelActive = controller.listMultiplierLevel.data?.map(item => {
      return item.levelId
    })
    const currentLevel = controller.detailExtraMultiplier.extraMultiplierInfo
      ? JSON.parse(
          controller.detailExtraMultiplier.extraMultiplierInfo
        )?.map((item: ExtraMultiplierInfoInterface) => {
          return item.id
        })
      : []

    const result = this.compareArrays(
      <Array<string | number>>levelActive,
      currentLevel
    )

    return result
  }

  @Watch('fieldMultiplier', { immediate: true, deep: true })
  onChangedMultiplier(value: fieldMultiplierInterface): void {
    this.form.multiplier = Object.entries(value).map(item => ({
      id: Number(item[0]),
      extraMultiplier: item[1],
    }))
  }

  private goToBack(): void {
    this.$router.push({ name: 'LoyaltyProgramExtraMultiplierPage' })
  }

  private onSubmit(): void {
    this.showModalConfirmation = true
  }

  private onModalAction(): void {
    this.showModalConfirmation = false
    this.updateExtraMultiplier()
  }

  private updateExtraMultiplier(): void {
    const payload: UpdateExtraMultiplierPayload = {
      id: Number(this.$route.params.id),
      channel: <EnumLoyaltiProgramChannel>this.form.channel.value,
      productType: <EnumExtraMultiplierProductType>this.form.product.value,
      extraMultiplierInfo: this.form.multiplier,
    }

    controller.updateExtraMultiplier(payload)
  }

  private getMultiplierLevelErrors(levelId: number): string | undefined {
    const validations = this.$v
    if (validations) {
      const form = validations.form
      if (form) {
        const levelRuleIndex = form.multiplier
        if (levelRuleIndex) {
          const index = this.form.multiplier.findIndex(item => {
            return item.id === levelId
          })
          const eachIndex = levelRuleIndex.$each[index]
          if (eachIndex) {
            if (!eachIndex.extraMultiplier.required) {
              return locale.label_validation_required
            }
            if (!eachIndex.extraMultiplier.minValue) {
              return locale.label_validation_min_value_1
            }
            return undefined
          }
        }
      }
    }
    return undefined
  }

  @Watch('controller.statusUpdateExtraMultiplier')
  onStatusUpdateExtraMultiplierChanged(status: string): void {
    if (
      status !== '' &&
      status === EventBusConstants.UPDATE_EXTRA_MULTIPLIER_SUCCESS
    ) {
      this.showModalConfirmation = false
      this.showModalSuccess = true
    }
    controller.setStatusUpdateExtraMultiplier('')
  }

  beforeDestroy(): void {
    controller.setStatusUpdateExtraMultiplier('')
  }
}
